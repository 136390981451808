import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Layout, Radio, Select } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Logo from "./Logo";
import NavSearch from "./NavSearch";
import { onMobileNavToggle, toggleCollapsedNav } from "redux/actions/Theme";
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from "constants/ThemeConstant";
import utils from "utils";
import { useFirebase } from "react-redux-firebase";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import roles from "../../roles";
import { useLocation } from "react-router-dom";
import { changeLanguage } from "../../translations/i18n";

const { Header } = Layout;
const { Option } = Select;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
  } = props;
  const firebase = useFirebase();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [searchActive, setSearchActive] = useState(false);
  const authEmail = useSelector((state) => state.firebaseReducer.profile.userEmail);
  const role = useSelector((state) => state.firebaseReducer.profile.userRole);

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === "dark" ? "#00000" : "#ffffff");
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  const handleSignOut = (e) => {
    e.preventDefault();
    firebase.logout().catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  const openOtherCms = (value) => {
    if (!!value) {
      const path = location.pathname;
      window.location.replace(value + path);
    }
  };

  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
            </ul>
          </div>
          <div className="nav-left">
            <div>
              {role === roles.superAdmin ? (
                <Select style={{ width: 250 }} defaultValue={null} onChange={openOtherCms}>
                  <Option value={null} style={{ width: "100%" }}>
                    {t("cms_navbar_title_nl")}
                  </Option>
                  <Option value={"https://cms.kuwait.chefsfarm.nl/"}>{t("cms_navbar_title_kw")}</Option>
                  <Option value={"https://cms.singapore.chefsfarm.nl/"}>{t("cms_navbar_title_sg")}</Option>
                </Select>
              ) : (
                <NavBarTitle className="ant-menu ant-menu-root ant-menu-horizontal">
                  {t("cms_navbar_title_nl")}
                </NavBarTitle>
              )}
            </div>
          </div>
          <div className="nav-right">
            <span>{authEmail}</span>
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              <li className="ant-menu-item ant-menu-item-only-child">
                <Button onClick={(e) => handleSignOut(e)}>{t("logout")}</Button>
              </li>
            </ul>
            <div className={"mr-2 pr-2 ml-2"}>
              <Radio.Group
                options={[
                  { label: "English", value: "en" },
                  { label: "Nederlands", value: "nl" },
                ]}
                onChange={(e) => {
                  console.log(e);
                  changeLanguage(e.target.value);
                }}
                value={i18n.language}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  );
};

const NavBarTitle = styled.p`
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
